.content-container {
  position: relative;
  font-size: calc(10px + 1vw);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vmax;
  background-color: rgb(0 0 0 / 20%);
  z-index: 2;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}