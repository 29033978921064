.cart {
    position: absolute;
    top: 84px;
    right: 0;
    width: 300px;
    height: calc(50vh);
    background-color: #fff;
    font-family: Raleway, sans-serif;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    overflow-y: auto;
    box-sizing: border-box;
}

.cart-header {
    font-size: calc(10px + 1vw);
    font-weight: bold;
    margin-bottom: 20px;
}

.cart-summary {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: calc(10px + 1vw);
    font-weight: bold;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: 20px 0;
}

.place-order-btn {
    background-color: #4CAF50 !important;
    color: white;
    padding: 15px;
    border: none;
    width: 90%;
    place-self: center;
    font-size: calc(10px + .75vw);
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.disabled-order-btn {
    background-color: grey !important;
    color: white;
    padding: 15px;
    border: none;
    width: 90%;
    font-size: calc(10px + .75vw);
    cursor: not-allowed !important;
    place-self: center;
    border-radius: 5px;
    margin-top: 20px;
}

.item-details {
    flex: 3;
}

.item-name,
.item-price {
    margin: 0;
}

.item-quantity {
    flex: 1;
    display: flex;
    align-items: center;
    margin: auto;
    align-self: center;
}

.cart-content {
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 0.6vw);
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.left-column,
.middle-column,
.right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left-column {
    flex: 0.9;
}

.middle-column {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    flex: 0.2;
}

.right-column {
    flex: 1;
    padding: 5px;
}

.size-btn,
.color-btn,
.quantity-btn {
    padding: 2px;
    margin: 2px;
    border: 1px solid black !important;
    font-size: 0.7em;
}

.color-btn {
    cursor: default !important;
    width: 20px;
    height: 20px;
}


.quantity-btn {
    width: 20px;
    height: 20px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    font-size: calc(10px + 1vw);
    border: 1px solid black;
}


.cart-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}