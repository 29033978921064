.product-container {
  display: flex;
  max-width: 80%;
  margin: auto;
  margin-top: 2rem;
}

@media (width <= 768px) {
  .product-container {
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin: auto;
  }
}