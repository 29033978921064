.product-text-header {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.size-selector,
.color-selector {
    margin-bottom: 1rem;
}

.size-selector button,
.color-selector button {
    border: 2px solid #ddd;
    background: none;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
}

.size-selector button.active,
.color-selector button.active {
    border-color: #333;
}

.attribute-button {
    width: 60px;
    height: 50px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 2px solid black;
    background-color: #fff;
}

.attribute-button-clicked {
    background-color: black;
    color: white;
    width: 60px;
    height: 50px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-color: orange;
}

@media (width <= 768px) {
    .attribute-container {
        display: grid;
        align-items: center;
        align-self: center;
        margin: auto;
        text-align: center;
    }
}