.image-gallery {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 80vh;
    flex: 1;
}

.main-image-container {
    position: relative;
    flex: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    display: block;
}

.thumbnail-container {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden auto;
    height: 100%;
    padding: 10px;
    object-fit: contain;
}

.thumbnail {
    max-height: 300px; /* Ensure the height does not exceed 20% of the container */
    max-width: 100%; /* Ensure the width does not exceed the container width */
    width: auto;
    margin-bottom: 10px;
    cursor: pointer;
}

.main-image-btns-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: rgb(0 0 0 / 50%);
    color: white;
    cursor: pointer;
    font-size: 24px;
    left: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.main-image-btns-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: rgb(0 0 0 / 50%);
    color: white;
    cursor: pointer;
    font-size: 24px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.main-image-btns :active {
    color: green;
}

@media (width <= 768px) {
    .image-gallery {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: 0 auto;
        height: auto;
    }

    .main-image-container,
    .thumbnail-container {
        flex: 1 0 100%;
        padding: 5px;
        height: auto;
        order: 2;
    }

    .main-image-container {
        order: 1;
    }

    .thumbnail-container {
        overflow-y: scroll;
        align-items: flex-start;
        flex-direction: row;
        padding: 5px 0;
        max-height: 200px;
    }

    .thumbnail {
        height: auto;
        max-height: 50px;
        width: auto;
        object-fit: cover;
        margin-bottom: 5px;
        display: block;
    }

    .main-image {
        max-height: 300px;
        width: auto;
        object-fit: contain;
    }
}